<template>
  <modal-wrapper @click-outside="onClose">

    <template v-slot:modal-header>
      <h2>
        <strong class="block">Edit Scheduled Config: </strong>
        <small>{{ name }}</small>
      </h2>
    </template>

    <modal-section
      title="Name"
      description="Name the scheduled configuration so you know what it's scheduled for"
    >
      <input
        class="styled__input w-full"
        type="text"
        v-model="name"
      />
    </modal-section>

    <modal-section
      title="Start/End"
      description="Date and time for when this configuration should start and stop running"
    >
      <label class="flex items-center">
        <span class="text-xs text-gray-600 w-14 mr-2 text-right">Start </span>
        <input
          class="styled__input w-full flex-grow"
          type="datetime-local"
          ref="start"
          :value="start"
          @change="startTimeChanged"
        />
      </label>
      <label class="flex items-center mt-4">
        <span class="text-xs text-gray-600 w-14 mr-2 text-right">End </span>
        <input
          class="styled__input w-full flex-grow"
          type="datetime-local"
          ref="end"
          :min="start"
          :value="end"
          @change="e => end = e.target.value"
        />
      </label>
      <p class="text-xs text-gray-600 mt-2 text-right">Total Running Time: {{ totalTime | durationPretty }}</p>
    </modal-section>

    <modal-section
      title="Wall View Order Preset to Use"
      description="Select one of the View Order Presets to use during this time."
    >
      <div class="styled__input">
        <div
          class="flex my-4"
          v-for="item in wallPresets"
          :key="item.id"
        >
          <label class="flex cursor-pointer">
            <input
              class="styled__checkbox mr-4"
              type="radio"
              name="default_viewmode"
              :checked="presetId === item.id"
              @input="e => presetId = item.id"
            />
            <span>{{ item.name }}</span>
          </label>
        </div>
      </div>
    </modal-section>

    <modal-section
      title="Creator Playlists to Use"
      description="Select all the creator playlists you'd like to see in the rotation for this time."
    >
      <div class="styled__input">
        <div
          class="my-4"
          v-for="item in playlists"
          :key="item.id"
        >
          <label class="flex cursor-pointer">
            <input
              class="styled__checkbox mr-4"
              type="checkbox"
              name="default_playlists"
              :checked="playlistIds.includes(item.id)"
              @input="e => onPlaylistInput(e, item.id)"
            />
            <span>{{ item.title }}</span>
          </label>
        </div>
      </div>
    </modal-section>

    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary ml-4"
        @click="done => onSave(done)"
      >Save</busy-button>
      <button
        class="styled__button"
        @click="onClose"
      >Cancel</button>
    </template>

  </modal-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ModalWrapper, ModalSection } from '@/components/Modal'
import BusyButton from '@/components/BusyButton'
import { DateTime } from 'luxon'

export default {

  name: 'WallScheduleModal',

  components: {
    ModalWrapper,
    ModalSection,
    BusyButton
  },

  props: {
    schedule: Object
  },

  data() {
    return {
      name: this.schedule.name,
      start: this.schedule.datetime_start.slice(0, 19),
      end: this.schedule.datetime_end.slice(0, 19),
      presetId: null,
      playlistIds: null
    }
  },

  computed: {
    ...mapState('cms', [
      'wallPresets',
      'playlists',
    ]),
    ...mapGetters('cms', [
      'defaultWallPresetId',
      'defaultPlaylistIds'
    ]),
    totalTime() {
      return Math.max(DateTime.fromISO(this.end) - DateTime.fromISO(this.start), 0)
    },
  },

  methods: {
    ...mapActions('cms', [
      'postScheduledWallConfig'
    ]),

    onPlaylistInput(e, id) {
      if (e.target.checked)
        this.playlistIds.push(id)
      else
        this.playlistIds = this.playlistIds.filter(i => i !== id)
      console.log(this.playlistIds, id)
    },

    startTimeChanged(e) {
      const end = DateTime.fromISO(e.target.value)
        .plus({ millisecond: this.totalTime }).toISO()
      this.start = e.target.value
      this.end = end.slice(0, 19)
    },

    onSave(done) {

      const validEnd = this.totalTime > 0 ? this.end :
        DateTime.fromISO(this.start)
          .plus({ minute: 1 }).toISO().slice(0, 19)

      const data = {
        name: this.name,
        datetime_start: this.start,
        datetime_end: validEnd,
        preset: this.presetId,
        playlists: this.playlistIds
      }

      this.postScheduledWallConfig({
        id: this.schedule.id,
        data
      }).then(() => {
        done()
        this.$emit('close')
      })

    },

    onClose() {
      this.$emit('close')
    }
  },

  created() {
    this.presetId = this.schedule.preset || this.defaultWallPresetId
    this.playlistIds = (this.schedule.playlists && [...this.schedule.playlists]) || this.defaultPlaylistIds
  },

}
</script>