<template>
  <div class="styled__container">

    <h2 class="styled__title mb-2">Import Playlist</h2>
    <p class="styled__description mb-6">Enter a Playlist ID from YouTube to extract the videos from it.</p>
    
    <input
      class="styled__input w-full max-w-lg mb-6"
      v-model="playlistImportId"
      type="text"
      placeholder="playlistID..."
    />

    <div class="flex items-center max-w-lg">

      <select
        class="styled__input flex-grow border-r-0 rounded-r-none"
        v-model="playlistImportType"
      >
        <option v-for="(type, key) in PlaylistTypes" :key="key" :value="key">
          {{ type }} Playlist
        </option>
      </select>

      <div class="flex-0 relative">
        <busy-button
          class="styled__button primary rounded-l-none"
          :disabled="playlistImportId === ''"
          @click="done => startImport(playlistImportType, playlistImportId, done)"
        >Import
        </busy-button>
      </div>

    </div>

    <p v-if="error" class=" mt-4 text-xs uppercase text-red-600">{{ error }}</p>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PlaylistTypes } from '@/store'
import BusyButton from '@/components/BusyButton'

export default {

  name: 'PlaylistImporter',
  
  components: {
    BusyButton
  },

  data() {
    return {
      PlaylistTypes,
      playlistImportId: '',
      playlistImportType: 'gallery',
      error: '',
      waiting: false,
    };
  },

  methods: {
    ...mapActions('cms', [
      'importPlaylist'
    ]),
    startImport(type, id, done) {
      this.error = "";
      this.importPlaylist({ type, id })
        .then(() => {
          this.playlistImportId = ''
          done()
        })
        .catch(err => {
          this.error = err;
          done()
        })
    }

  }

}
</script>