<template>
  <main class="flex justify-center">
    <div class="styled__container">

      <div class="flex flex-col items-center p-6">

        <img
          :src="require('@/assets/di-logo.png')" alt="Dimensional Innovations"
          class="w-32 h-auto mb-9"
        />

        <form>
          <div class="block mb-4">
            <input class="styled__input" name="secret" type="text" placeholder="user" v-model="user" required>
          </div>
          <div class="block">
            <input class="styled__input" name="secret" type="password" placeholder="password" v-model="password" required>
          </div>
          <div class="text-center">
            <button class="styled__button mt-6" type="submit" @click="submit">Login</button>
          </div>
        </form>

      </div>

    </div>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

  name: 'Login',

  data() {
    return {
      user: '',
      password: ''
    }
  },

  computed: {
    ...mapState('auth', [
      'authenticated'
    ])
  },

  methods: {

    ...mapActions('auth', [
      'submitLogin',
      'clearLogin',
      'checkAuth'
    ]),

    submit(e) {
      e.preventDefault()
      const { user, password } = this
      this.clearLogin()
      this.submitLogin({ user, password })
        .then(() => this.checkAuth())
        .then(() => {
          console.log('[Login] authenticated:', this.authenticated)
          if (this.authenticated) {
            this.$router.push('/playlists')
          }
        })
    }

  }

}
</script>