<template>
  <div class="styled__container pb-0">

    <h2 class="styled__title mb-4">Playlists Imported – {{ typeLabel }}</h2>

    <div class="bg-gray-200 -mx-6 p-6">

      <loading-dots v-if="!playlists" class="block h-8 w-12 mx-auto my-4" />
      <p v-else-if="sorted.length === 0" class="text-gray-400 text-center my-4">No playlists found for {{ typeLabel }}.</p>
      <div v-else class="rounded overflow-hidden">
        <playlist-item
          v-for="item in sorted"
          :key="item.id"
          :data="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingDots from '@/assets/svg/three-dots.svg?inline'
import PlaylistItem from './PlaylistItem'
import { PlaylistTypes } from '@/store'

export default {

  name: 'PlaylistManager',

  components: {
    LoadingDots,
    PlaylistItem
  },

  props: {
    type: String,
    playlists: Array
  },

  computed: {
    sorted() {
      return this.playlists?.filter(p => p.playlist_type === this.type).sort((a, b) => a.id - b.id)  || []
    },
    typeLabel() {
      return PlaylistTypes[this.type]
    }
  }

}
</script>