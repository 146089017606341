import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import '@/assets/css/index.css'
import { DateTime } from 'luxon'

Vue.config.productionTip = false

Vue.mixin({

  filters: {
    descriptionEllipsis(value) {
      return value.length <= 280 ? value : value.slice(0, 280).concat('…')
    },
    durationPretty(value) {
        let s = value / 1000
        const h = parseInt(s / 3600)
        s = s % 3600
        const m = parseInt(s / 60)
        s = s % 60
        return `${h}h ${m}m ${s}s`
    },
    datePretty(value) {
      if (!value) return ''
      return DateTime.fromISO(value)
        .setZone('America/Los_Angeles')
        .toLocaleString(DateTime.DATE_MED)
    },
    timePretty(value) {
      if (!value) return ''
      return DateTime.fromISO(value)
        .setZone('America/Los_Angeles')
        .toLocaleString(DateTime.TIME_MED)
    },
    dateTimePretty(value) {
      if (!value) return ''
      return DateTime.fromISO(value)
        .setZone('America/Los_Angeles')
        .toLocaleString(DateTime.DATETIME_MED)
    },
    dateTimeShort(value) {
      if (!value) return ''
      return DateTime.fromISO(value)
        .setZone('America/Los_Angeles')
        .toLocaleString(DateTime.DATETIME_SHORT)
    },
    urlPretty(value) {
      if (!value) return ''
      return value.replace(/(^\w+:|^)\/\//, '').replace(/\/+$/, '');
    },
    numberCompact(value) {
      if (!value) return ''
      return Intl.NumberFormat('en', { notation: 'compact' }).format(value);
    }
  },

  methods: {
    showAppModal(component, props) {
      const app = this.$root.$children[0]
      app.showModal(component, props)
      
    },
    closeAppModal() {
      const app = this.$root.$children[0]
      app.closeModal()
    }
  }

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
