import * as AWS from 'aws-sdk'

const s3 = new AWS.S3({
  accessKeyId: 'AKIA3EKIMMESTMLM3F4G',
  secretAccessKey: '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',
  signatureVersion: 'v4',
  endpoint: 'https://s3.us-west-1.amazonaws.com',
  region: 'us-west-1'
})

export function uploadToS3({ file, onProgress, onSuccess, onError }) {

  const ext = file.name.split('.').pop()
  const name = file.name.split('.')[0]
  const now = Date.now()
  const filename = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '') + "-" + now + "." + ext
  const key = `youtube/${filename}`

  console.log('[store.aws] uploadToS3, key:', key)

  const params = {
    Bucket: 'di-sofi',
    Key: key,
    Body: file,
    ContentType: file.type,
    ACL: 'public-read',
  }

  const uploader = s3.upload(params, (err, data) => {
    if (err) {
      console.warn('[store.aws] there was an error uploading the file to s3: ', err)
      if (onError)
        onError(err)
    } else {
      console.log('[store.aws] upload results:', data)
      if (onSuccess)
        onSuccess(data)
    }
  })

  if (onProgress)
    uploader.on('httpUploadProgress', onProgress)

  return uploader

}

export function removeFromS3({ url, onSuccess, onError }) {

  // using pathname of url as key and removing first/last forward slashes (if any)
  const key = new URL(url).pathname.replace(/^\/|\/$/g, '')

  console.log('[store.aws] removeFromS3, key:', key)

  const params = {
    Bucket: 'di-sofi',
    Key: key,
  }

  const remover = s3.deleteObject(params, (err, data) => {
    if (err) {
      console.warn('[store.aws] there was an error removing the file from s3:', err)
      if (onError)
        onError(err)
    } else {
      console.log('[store.aws] removal results:', data)
      if (onSuccess)
        onSuccess(data)
    }
  })

  return remover

}