<template>
  <tr class="opacity-80 bg-gray-100">
    <td class="w-64">
        <div class="flex items-left">
            <div class="ml-4 -mt-1 text-left">
            {{ fullscreen.name }}
            </div>
        </div>
    </td>
    <td>
        <div class="inline-block relative">
            {{ fullscreen.location }}
        </div>
    </td>
    <td>
        <div class="inline-block relative">
            {{ type }}
        </div>
    </td>
    <td>
      <busy-toggle
        :value="fullscreen.is_active"
        @change="(value, done) => onActiveChange(fullscreen.id, value, done)"
      />
    </td>
    <td>
      <div class="text-right whitespace-nowrap">
        <button class="styled__link" @click="onEdit">Edit</button>
        <button class="styled__link ml-4" @click="onRemove(fullscreen.id)">Remove</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmModal from '@/components/ConfirmModal'
import FullscreenModal from './FullscreenModal'
import BusyToggle from '@/components/BusyToggle'

export default {
  
  name: 'FullscreenRow',
  
  components: {
    BusyToggle,
  },

  props: {
    fullscreen: Object
  },

  computed: {
    type() {
      if (this.fullscreen.video)
        return 'video'
      else if (this.fullscreen.feature_image)
        return 'image'
      else return ''
    },
    active() {
      return this.fullscreen.is_active || false
    }
  },

  methods: {

    ...mapActions('cms', [
      'deleteFullscreen',
      'postFullscreen'
    ]),

    onEdit() {
      this.showAppModal(FullscreenModal, {
        fullscreen: this.fullscreen
      })
    },
    
    onRemove(id) {
      this.showAppModal(ConfirmModal, {
        title: 'Delete Fullscreen',
        message: 'Are you sure you want to delete this fullscreen takeover?',
        onResponse: (confirm, resolve) => confirm
          ? this.deleteFullscreen({ id }).then(() => resolve())
          : resolve()
      })
    },

    onActiveChange(id, value, done) {
      const data = { is_active: value }
      this.postFullscreen({ id, data })
        .then(() => done())
    },

  },
  
}
</script>