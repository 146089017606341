<template>
  <tr :class="expired && 'opacity-80 bg-gray-100'">
    <td>
      <div class="flex items-center">
        <div
          class="flex-shrink-0 h-10 w-10 rounded-full bg-center bg-cover bg-gray-400 overflow-hidden"
          :style="{ backgroundImage: `url(${event.thumbnail_image})`}"
        ></div>
        <div class="ml-4 -mt-1">
          {{ event.event_title }}
        </div>
      </div>
    </td>
    <td>
      <div
        v-for="(item, index) in event.event_dates"
        :key="`${index}_${item.id}`"
        class="whitespace-nowrap h-6 my-2"
      >
        <div class="inline-block relative">
          {{ item.date | dateTimePretty }}
          <div
            v-if="dateExpired(item.date)"
            class="absolute transform -translate-x-full -translate-y-1/2
              top-1/2 -left-2 text-xs uppercase text-white bg-red-400 px-2 py-1 rounded-md"
          >Expired</div>
        </div>
      </div>
      <div v-if="event.event_dates.length === 0">No Dates</div>
    </td>
    <td>
      <div
        v-for="(item, index) in event.event_dates"
        :key="`${index}_${item.id}`"
        class="whitespace-nowrap h-6 my-2"
      >
        <busy-toggle
          :value="item.sold_out"
          @change="(value, done) => onSoldOutChange(item.id, value, done)"
        />
      </div>
    </td>
    <td>
      <busy-toggle
        :value="event.use_as_billboard"
        @change="(value, done) => onBillboardChange(event.id, value, done)"
      />
    </td>
    <td>
      <div class="text-right whitespace-nowrap">
        <button class="styled__link" @click="onEdit">Edit</button>
        <button class="styled__link ml-4" @click="onRemove(event.id)">Remove</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmModal from '@/components/ConfirmModal'
import EventModal from './EventModal'
import BusyToggle from '@/components/BusyToggle'
import { DateTime } from 'luxon'

export default {
  
  name: 'EventRow',

  components: {
    BusyToggle
  },

  props: {
    event: Object
  },

  computed: {
    expired() {
      return this.event.event_dates.length > 0 &&
        this.event.event_dates.every(item => DateTime.now() > DateTime.fromISO(item.date))
    }
  },

  methods: {

    ...mapActions('cms', [
      'deleteEvent',
      'postEvent',
      'postEventDate'
    ]),

    dateExpired(date) {
      return DateTime.now() > DateTime.fromISO(date)
    },

    onEdit() {
      this.showAppModal(EventModal, {
        event: this.event
      })
    },

    onBillboardChange(id, value, done) {
      const data = { use_as_billboard: value }
      this.postEvent({ id, data })
        .then(() => done())
    },

    onSoldOutChange(id, value, done) {
      const data = { sold_out: value }
      this.postEventDate({ id, data })
        .then(() => done())
    },

    onRemove(id) {
      this.showAppModal(ConfirmModal, {
        title: 'Delete Event',
        message: 'Are you sure you want to delete this event?',
        onResponse: (confirm, resolve) => confirm
          ? this.deleteEvent({ id }).then(() => resolve())
          : resolve()
      })
    },

  }
  
}
</script>