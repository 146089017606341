<template>
  <div class="border-b border-gray-300">

    <div class="bg-gray-100 px-6 py-4 border-b border-gray-300 md:flex md:items-center">
      <div class="mb-4 md:flex-grow md:mb-0">
        <h3 class="text-gray-900">{{ data.title }}</h3>
        <p class="text-xs text-gray-600">id: {{ data.playlist_id }}</p>
        <p class="text-xs text-gray-600">{{ data.videos.length }} Videos from {{ data.channels.length }} Creators</p>
      </div>
      <div class="flex flex-col items-start md:flex-row gap-4 md:items-center md:justify-end md:flex-grow-0">
        <button class="styled__link" @click="onRemove(data.id)">Remove</button>
        <busy-button class="styled__link" @click="done => onRefresh(data.playlist_type, data.playlist_id, done)">Refresh</busy-button>
        <button class="styled__link" @click="expanded = !expanded">
          Manage Creators
          <icon-arrow class="inline-block -mt-1" :style="{ transform: `rotate(${expanded ? 180 : 0 }deg)` }" />
        </button>
      </div>
    </div>

    <div class="bg-white overflow-x-auto" :class="{ 'hidden': !expanded }">
      <table class="styled__table" style="min-width:900px">
        <thead>
          <tr>
            <th class="text-left">Content Creator</th>
            <th>Approved</th>
            <th>Use As Billboard</th>
            <th>Has Featured Image</th>
            <th><span class="sr-only">Edit</span></th>
          </tr>
        </thead>
        <tbody>
          <playlist-channel-row
            v-for="(channel, index) in data.channels"
            :key="channel.id"
            :count="index+1"
            :channel="channel"
            :videos="getVideosForChannelId(channel.channel_id)"
            @edit="selectedChannel = channel"
          />
        </tbody>
      </table>
    </div>
  
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import IconArrow from '@/assets/svg/arrow.svg?inline'
import BusyButton from '@/components/BusyButton'
import ConfirmModal from '@/components/ConfirmModal'
import PlaylistChannelRow from './PlaylistChannelRow'

export default {

  name: 'PlaylistItem',

  components: {
    IconArrow,
    BusyButton,
    ConfirmModal,
    PlaylistChannelRow
  },

  props: {
    data: Object
  },

  data() {
    return {
      expanded: false
    }
  },

  methods: {

    ...mapActions('cms', [
      'deletePlaylist',
      'importPlaylist'
    ]),

    onRemove(id) {
      this.showAppModal(ConfirmModal, {
        title: 'Delete Playlist',
        message: 'Are you sure you want to delete this playlist?',
        onResponse: (confirm, resolve) => confirm
          ? this.deletePlaylist({ id }).then(() => resolve())
          : resolve()
      })
    },

    onRefresh(type, id, done) {
      this.importPlaylist({ type, id }).then(() => done())
    },

    getVideosForChannelId(channelId) {
      return this.data.videos.filter(item =>
        item.api_data.snippet.videoOwnerChannelId === channelId)
    }

  }

}
</script>