<template>
  <main>
    <sync-manager />
  </main>
</template>

<script>
import SyncManager from '@/components/SyncManager'
export default {
  name: 'Sync',
  components: {
    SyncManager
  }
}
</script>