<template>
  <modal-wrapper @click-outside="onClose">

    <template v-slot:modal-header>
      <h2><strong>Review Creator:</strong></h2>
    </template>

    <div class="my-6 bg-gray-100 rounded px-6 py-4 border">
      <div class="flex items-center">
        <div
          class="flex-shrink-0 h-16 w-16 rounded-full bg-center bg-cover bg-gray-400 overflow-hidden"
          :style="{ backgroundImage: `url(${channel.api_data.snippet.thumbnails.default.url})`}"
        ></div>
        <div class="ml-4">
          <p class="font-medium text-gray-900">
            {{ channel.api_data.snippet.title }}
          </p>
          <p class="text-gray-500 text-xs">
            {{ channel.api_data.statistics.subscriberCount | numberCompact }} Subscribers
          </p>
        </div>
      </div>
    </div>

    <modal-section
      title="Creator Blurb"
      description="The creator's description that will appear when they are selected in the 'Gallery' view mode."
    >
      <p class="styled__input w-full h-40"
      >{{ description || channel.api_data.snippet.description | descriptionEllipsis }}</p>
    </modal-section>

    <modal-section
      title="Feature Image"
      description="The creator's poster image that will be displayed during the 'Featured Creators' view mode."
    >
      <figure class="styled__figure w-full">
        <div class="aspect-w-16 aspect-h-9">
          <img
            v-if="image.preview"
            :src="image.preview"
            :alt="channel.api_data.snippet.title"
          />
        </div>
        <figcaption v-if="!image.preview">Image not yet added.</figcaption>
      </figure>
    </modal-section>

    <modal-section
      title="Videos in Playlist"
      description="Videos this creator has in the playlist that will be used in the 'Gallery' view mode. Only the first 30s of these videos will be used."
    >
      <div
        class="relative mb-6"
        v-for="(video, index) in videos"
        :key="video.video_id"
      >
        <span class="absolute -left-4 -top-4 rounded-full bg-red-600 text-white w-8 h-8 text-md pt-1 text-center z-10">{{ index+1 }}</span>
        <div class="aspect-w-16 aspect-h-9 overflow-visible bg-gray-200 last:mb-0 mb-4 z-0">
          <iframe
            :src="`https://www.youtube.com/embed/${video.video_id}`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p class="text-xs text-gray-400 -mt-2 mb-9">
          Views: {{ video.api_data.statistics.viewCount | numberCompact }}, 
          Likes: {{ video.api_data.statistics.likeCount | numberCompact }}
        </p>
      </div>
    </modal-section>

    <modal-section
      title="Approval Notes"
      description="Optionally add some notes as to why this content cannot be approved."
    >
      <textarea
        class="styled__input w-full h-40"
        placeholder="Enter notes..."
        v-model="notes"
      ></textarea>
    </modal-section>

    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary approve ml-4"
        @click="done => onSave(true, done)"
      >Approve</busy-button>
      <busy-button
        class="styled__button primary ml-4"
        @click="done => onSave(false, done)"
      >Save Notes</busy-button>
      <button
        class="styled__button"
        @click="onClose"
      >Cancel</button>
    </template>

  </modal-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import { ModalWrapper, ModalSection } from '@/components/Modal'
import BusyButton from '@/components/BusyButton'

export default {

  name: 'PlaylistChannelModalReview',

  components: {
    ModalWrapper,
    ModalSection,
    BusyButton
  },

  props: {
    channel: Object,
    videos: Array
  },

  data() {
    return {
      description: this.channel.custom_blurb,
      notes: this.channel.approval_notes,
      image: {
        preview: this.channel.feature_image,
        file: null
      }
    };
  },

  methods: {

    ...mapActions('cms', [
      'postChannel',
    ]),

    onClose() {
      this.$emit('close');
    },

    onSave(approved, done) {

      const data = {
        approved,
        approval_notes: approved ? null : this.notes
      }

      this.postChannel({
        id: this.channel.id,
        data
      }).then(() => {
        done()
        this.$emit('close')
      })
      
    }

  }

}
</script>