<template>
  <div class="w-full">
    <h3 class="styled__label mb-2">{{ syncId }} status:</h3>
    <div class="styled__status h-48" ref="status">
      <p v-for="(item, index) in messages" :key="index">{{ item }}</p>
    </div>
    <div class="mt-4">
      <busy-button
        class="styled__button primary"
        :disabled="busy"
        @click="done => runSync(done)"
      >Sync</busy-button>
      <busy-button
        class="styled__button ml-2 "
        :disabled="busy"
        @click="done => deleteLocal(done)"
      >Delete</busy-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BusyButton from '@/components/BusyButton'
import ConfirmModal from '@/components/ConfirmModal'

export default {

  name: 'SyncRunner',

  components: {
    BusyButton
  },

  props: {
    syncId: String
  },

  data() {
    return {
      responseWait: 3000,
      busy: false,
      messages: []
    }
  },

  computed: {
    ...mapState('socket', [
      'whenConnected'
    ])
  },
  
  methods: {

    ...mapActions('socket', [
      'sendAction'
    ]),

    sendSyncMessage(type, wait, data) {
      return new Promise(resolve => {

        this.busy = true
        this.messages = []
        this.messages.push(`Trying connection to ${this.syncId} sync...`)

        this.sendAction({
          action: `${this.syncId}_content_sync_${type}`,
          data,
          wait,
          update: data => {
            this.messages.push(data.status)
            this.$nextTick(() => this.updateStatusScroll(this.syncId))
          },
          complete: () => {
            this.busy = false
            resolve()
          },
          timeout: () => {
            this.messages.push(`Response timeout.`)
            this.busy = false
            resolve()
          }
        })

      })
    },

    updateStatusScroll() {
      const $el = this.$refs.status
      if ($el) {
        $el.scrollTo({
          top: $el.scrollHeight - $el.clientHeight,
          // behavior: 'smooth'
        })
      }
    },

    checkSync() {
      this.sendSyncMessage('check', 3000, {})
    },

    runSync(done) {
      this.sendSyncMessage('run', -1, {})
        .then(() => done())
    },

    deleteLocal(done) {
      this.showAppModal(ConfirmModal, {
        title: `Delete Local Content: ${this.syncId}`,
        message: `This will delete all the local content on the ${this.syncId} computer and you will have to re-sync everything, are you sure?`,
        onResponse: (confirm, resolve) => {
          resolve()
          confirm ?
            this.sendSyncMessage('delete', -1, {}).then(() => done()) :
            done()
        }
      })
    },
    
  },

  mounted() {
    this.whenConnected.then(() => this.checkSync())
  }

}
</script>