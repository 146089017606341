<template>
  <div class="styled__container">
    <h2 class="styled__title mb-2">Icon Controls</h2>
    <p class="styled__description">
      Control various parts of the icon in real time using these controls
    </p>

    <div class="flex flex-col my-12">

      <h3 class="styled__label mb-4 text-center">Jump to Mode</h3>

      <div class="flex flex-wrap justify-center gap-4 mb-12">
        <button
          v-for="item in iconModes"
          :key="item[1]"
          @click="setIconMode(item[1])"
          class="styled__button w-4/12"
        >
          {{ item[0] }}
        </button>
      </div>

      <h3 class="styled__label mb-4 text-center">AutoPlay</h3>

      <div class="flex justify-center">
        <busy-toggle
          :value="autoplay"
          @change="(value, done) => setIconState('autoplay', value, done)"
        />
      </div>

    </div>
  </div>
</template>

<script>
import BusyToggle from '@/components/BusyToggle'
import BusyButton from '@/components/BusyButton'
import { mapActions } from 'vuex'

export default {
  name: 'IconControls',

  components: {
    BusyButton,
    BusyToggle
  },

  data() {
    return {
      autoplay: true,
      redtakeover: false,
      blackout: false,
      iconModes: [
        // ['Galaxy', 'galaxy'],
        ['Red', 'm_red'],
        ['Black', 'm_black'],
        ['Time Machine', 'm_time_machine'],
        ['Fun House', 'm_funhouse'],
        ['Tri-Twist', 'm_tri_twist'],
        ['Tri-Tunnel', 'm_tri_tunnel'],
        ['Blocks', 'm_blocks'],
        // ['Rainbow', 'm_rainbow'],
        ['Flip', 'm_flip'],
        ['Fullscreens', 'm_fullscreens']
      ]
    }
  },

  methods: {
    ...mapActions('socket', ['sendAction']),

    setIconMode(mode) {
      this.sendAction({
        action: 'icon_mode',
        data: { mode }
      })
    },

    setIconState(dataName, value, done) {
      this[dataName] = value
      this.sendAction({
        action: `icon_state`,
        data: { property: dataName, value }
      })
      done()
    }
  }
}
</script>
