<template>
  <div class="fixed py-16 z-10 inset-0 flex items-start justify-center overflow-y-scroll">
    <div class="fixed z-0 inset-0" aria-hidden="true" @click="$emit('click-outside')">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <div :class="`block z-10 bg-white rounded-lg text-left overflow-hidden shadow-xl max-w-${maxWidth || '2xl'} w-full mx-6`">
      <div class="bg-white p-8 opacity-100">
        <div class="text-lg leading-6 font-medium text-gray-900 mb-2">
          <slot name="modal-header" />
        </div>
        <slot />
      </div>
      <div class="bg-gray-100 p-6 flex flex-row-reverse">
        <slot name="modal-footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWrapper',
  props: {
    maxWidth: String
  }
}
</script>