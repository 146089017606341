<template>
  <main>
    <events-manager />
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import EventsManager from '@/components/EventsManager'

export default {

  name: 'Events',

  components: {
    EventsManager
  },

  methods: {
    ...mapActions('cms', [
      'fetchEvents',
    ])
  },

  created() {
    this.fetchEvents()
  }

}
</script>
