<template>
  <div class="styled__container">

    <h2 class="styled__title">Default Configuration – Icon</h2>

    <div class="my-6">
      <span class="block text-sm mb-2">Icon Mode to Use</span>
      <loading-dots v-if="!iconModes" class="inline-block h-8 w-12" opacity="0.25" stroke="#000" fill="#000" />
      <p v-else-if="iconModes.length === 0" class="text-gray-400 text-xs my-2">No icon modes found</p>
      <div v-else class="styled__input">
        <div
          class="flex my-4"
          v-for="item in iconModes"
          :key="item.id"
        >
          <label class="flex cursor-pointer">
            <input
              class="styled__checkbox mr-4"
              type="radio"
              name="default_icon_mode"
              :checked="item.id === defaultIconModeId"
              @input="e => onDefaultIconModeInput(e, item.id)"
            />
            <span>{{ item.name }}</span>
          </label>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import LoadingDots from '@/assets/svg/three-dots.svg?inline'

export default {

  name: 'IconDefaultConfig',
  
  components: {
    LoadingDots,
  },

  computed: {
    ...mapState('cms', [
      'iconModes'
    ]),
    ...mapGetters('cms', [
      'defaultIconModeId',
    ])
  },

  methods: {
    ...mapActions('cms', [
      'postDefaultIconMode',
    ]),
    onDefaultIconModeInput(e, id) {
      e.preventDefault()
      const input = e.target
      const value = e.target.checked
      input.disabled = true
      this.postDefaultIconMode({ id, value, })
        .then(res => {
          input.disabled = false
          input.checked = true
        })
    },
  }

}
</script>