<template>
  <modal-wrapper @click-outside="onCancel">

    <template v-slot:modal-header>
      <h2><strong>Edit Fullscreen Takeover: </strong>{{ name }}</h2>
    </template>

    <modal-section
      name="name"
      title="Name"
    >
      <input
        class="styled__input w-full"
        type="text"
        v-model="name"
      />
    </modal-section>

    <modal-section
      name="location"
      title="Location"
    >
      <select
        class="styled__input w-full"
        v-model="location"
      >
        <option v-for="(location, key) in AppLocations" :key="key" :value="key">
          {{ location }}
        </option>
      </select>
    </modal-section>

    <modal-section
      title="Video"
      :description="`A video to play as a fullscreen takeover. \nWall: 2880x1080 \nIcon: 1234x1234`"
    >
      <figure class="styled__figure w-full">
        <div>
          <video
            v-if="video.preview"
            :src="video.preview"
            :alt="fullscreen.name"
            class="w-full"
            controls
            autoplay
            muted
          ></video>
        </div>
      </figure>
      <div class="flex w-full items-center justify-between mt-4">
        <input
          class="text-sm"
          type="file"
          ref="videoInput"
          @change="onFileChange('videoInput', 'video')"
        />

        <button
          v-if="video.preview"
          class="styled__button"
          @click="onFileRemove('videoInput', 'video')"
        >{{ uploadingVideo ? 'Cancel' : 'Remove' }}</button>
      </div>        
      <div v-if="uploadingVideo" class="relative pt-5">
        <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
          <div
            :style="{ 'width': `${video.progress}%` }"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-600"
          ></div>
        </div>
      </div>
    </modal-section>

    <modal-section
      title="Image"
      description="An image to display as a fullscreen takeover. It will be displayed for 72 seconds. If a video is added, this image will be ignored."
    >
      <figure class="styled__figure w-full">
        <div>
          <img
            v-if="image.preview"
            :src="image.preview"
            :alt="fullscreen.name"
          />
        </div>
      </figure>
      <div class="flex w-full items-center justify-between mt-4">
        <input
          class="text-sm"
          type="file"
          ref="imageInput"
          @change="onFileChange('imageInput', 'image')"
        />
        <button
          v-if="image.preview"
          class="styled__button"
          @click="onFileRemove('imageInput', 'image')"
        >Remove</button>
      </div>
    </modal-section>

    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary ml-4"
        :disabled="uploadingVideo"
        @click="done => onSave(done)"
      >Save</busy-button>
      <button
        class="styled__button"
        @click="onCancel"
      >Cancel</button>
    </template>

  </modal-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import { AppLocations } from '@/store'
import { ModalWrapper, ModalSection } from '@/components/Modal'
import BusyButton from '@/components/BusyButton'
import { uploadToS3, removeFromS3 } from '@/store'

export default {

  name: 'FullscreenModal',

  components: {
    ModalWrapper,
    ModalSection,
    BusyButton
  },

  props: {
    fullscreen: Object
  },

  data() {
    return {
      AppLocations,
      name: this.fullscreen.name,
      location: this.fullscreen.location,
      image: {
        preview: this.fullscreen.feature_image,
        file: null,
      },
      video: {
        preview: this.fullscreen.video,
        file: null,
        url: null,
        progress: 0,
        uploader: null,
      },
    };
  },

  computed: {
    uploadingVideo() {
      return this.video.uploader !== null
    },
  },

  methods: {

    ...mapActions('cms', [
      'postFullscreen',
    ]),

    onFileChange(ref, type) {
      const files = this.$refs[ref].files
      if (files.length > 0) {
        const file = files[0]
        if (type === 'image') {
          this.image.preview = URL.createObjectURL(file)
          this.image.file = file
        } else if (type === 'video') {
          this.video.preview = URL.createObjectURL(file)
          this.video.file = file
        }
      }
    },

    onFileRemove(ref, type) {
      this.$refs[ref].value = null
      if (type === 'image') {
        this.image.preview = null
      } else if (type === 'video') {
        this.video.preview = null
        this.video.file = null
        this.video.url = null
        if (this.video.uploader) {
          this.video.uploader.abort()
          this.video.uploader = null
          this.video.progress = 0
        }
      }
    },

    async onSave(done) {

      // first upload video, to get video url
      if (this.video.file) {
        try {
          await new Promise((resolve, reject) => {
            this.video.uploader = uploadToS3({
              file: this.video.file,
              onProgress: p => this.video.progress = Math.round(p.loaded / p.total * 100),
              onSuccess: data => {
                this.video.url = data.Location
                this.video.progress = 0
                this.video.uploader = null
                resolve()
              },
              onError: () => reject()
            })
          })
        } catch (err) {
          done()
          return
        }
      }

      const data = {
        name: this.name,
        location: this.location
      }

      if (this.image.file) {
        data.feature_image = this.image.file
      } else if (this.image.preview === null) {
        data.feature_image = null
      }

      if (this.video.url) {
        data.video = this.video.url
      } else if (this.video.preview === null) {
        data.video = null
      }

      await this.postFullscreen({ id: this.fullscreen.id, data })

      done()
      this.$emit('close')

    },

    async onCancel() {

      // kill uploader if uploading
      if (this.video.uploader)
        this.video.uploader.abort()
      
      this.$emit('close')

    }

  },

}
</script>