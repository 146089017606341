<template>
  <label
    class="inline-flex items-center justify-center cursor-pointer"
    :class="{
      'animate-pulse': busy,
      'opacity-25': disabled,
      'pointer-events-none' : busy || disabled
    }"
    @click.prevent="onClick"
  >
    <input type="checkbox" class="hidden" :checked="value" />
    <div class="relative">
      <div class="w-10 h-4 rounded-full shadow-inner bg-gray-300"></div>
      <loading-dots
        v-if="busy"
        class="block absolute top-1/4 left-1/4 w-1/2 h-1/2"
      />
      <div
        class="absolute w-6 h-6 -ml-1 rounded-full shadow top-1/2 left-0 transform transition-transform -translate-y-1/2"
        :class="
          `${visibleValue ? `${ color ? 'bg-'+color : 'bg-red-500' } translate-x-full` : 'bg-white translate-x-0'} `+
          `${busy ? 'scale-50' : 'scale-1'}`"
      ></div>
    </div>
    <div v-if="$slots.default" class="styled__label text-center pl-4">
      <slot></slot>
    </div>
  </label>
</template>

<script>
import LoadingDots from '@/assets/svg/three-dots.svg?inline'

export default {
  
  name: 'BusyToggle',

  components: {
    LoadingDots
  },

  props: {
    color: String,
    disabled: Boolean,
    value: Boolean
  },

  data() {
    return {
      busy: false,
      visibleValue: this.value
    }
  },

  watch: {
    value(val) {
      this.visibleValue = val
    }
  },

  methods: {
    onClick() {
      this.busy = true
      this.visibleValue = !this.value
      this.$emit('change', this.visibleValue, () => {
        this.$nextTick(() => this.visibleValue = this.value)
        this.busy = false
      })
    }
  }

}
</script>