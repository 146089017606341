<template>
  <main>
    <playlist-importer />
    <playlist-manager type="gallery" :playlists="playlists" />
    <playlist-manager type="shorts" :playlists="playlists" />
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PlaylistImporter from '@/components/PlaylistImporter'
import PlaylistManager from '@/components/PlaylistManager'

export default {

  name: 'Playlists',

  components: {
    PlaylistImporter,
    PlaylistManager
  },

  computed: {
    ...mapState('cms', [
      'playlists'
    ])
  },

  methods: {
    ...mapActions('cms', [
      'fetchPlaylists'
    ])
  },

  created() {
    this.fetchPlaylists()
  }

}
</script>