<template>
  <div id="app">
    <nav-bar />
    <page-header :title="$route.name" />
    <router-view />
    <component
      v-if="modal"
      :is="modal.component"
      v-bind="modal.props"
      @close="closeAppModal"
    />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import PageHeader from '@/components/PageHeader'
import { mapActions } from 'vuex'
import { ModalContainer } from '@/components/Modal'

export default {

  name: 'App',

  components: {
    NavBar,
    PageHeader,
    ModalContainer
  },

  data() {
    return {
      modal: null
    }
  },

  methods: {

    ...mapActions('socket', [
      'connect',
      'disconnect'
    ]),

    showModal(component, props) {
      this.modal = { component, props }
      document.documentElement.style.overflow = 'hidden'
    },

    closeModal() {
      this.modal = null
      document.documentElement.style.overflow = ''
    }

  },

  created() {
    this.connect()
  },

  destroyed() {
    this.disconnect()
  }

}
</script>