<template>
  <div class="styled__container pb-0">

    <h2 class="styled__title mb-4">Fullscreen Takeovers – {{ locationLabel }}</h2>
    
    <div class="bg-gray-200 -mx-6 p-6">

      <loading-dots v-if="!fullscreensForLocation" class="block h-8 w-12 mx-auto my-4" />
      <p v-else-if="fullscreensForLocation.length === 0" class="text-gray-400 text-center my-4">No fullscreen takeovers found for {{ locationLabel }}.</p>
      <div v-else class="overflow-x-auto">
        <table class="styled__table rounded overflow-hidden" style="min-width:768px">
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th>Location</th>
              <th>Type</th>
              <th>Active</th>
              <th><span class="sr-only">Edit</span></th>
            </tr>
          </thead>
          <tbody>
            <fullscreen-row
              v-for="fs in fullscreensForLocation"
              :key="fs.id"
              :fullscreen="fs"
            />
          </tbody>
        </table>
      </div>

      <div class="flex justify-end">
        <button
          class="styled__button primary mt-6"
          @click="onCreate"
        >Add New</button>
      </div>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AppLocations } from '@/store'
import LoadingDots from '@/assets/svg/three-dots.svg?inline'
import FullscreenRow from './FullscreenRow'
import FullscreenModal from './FullscreenModal'

export default {

  name: 'EventsManager',

  props: {
    location: String,
  },

  components: {
    LoadingDots,
    FullscreenRow
  },

  computed: {

    ...mapState('cms', [
      'fullscreens'
    ]),

    locationLabel() {
      return AppLocations[this.location]
    },

    fullscreensForLocation() {
      return this.fullscreens?.filter(fullscreen => fullscreen.location === this.location) || []
    }

  },

  methods: {

    onCreate() {

      this.showAppModal(FullscreenModal, {
        fullscreen: {
          name: 'New Fullscreen',
          location: this.location
        }
      })
      
    }

  }

}
</script>