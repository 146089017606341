<template>
  <div class="styled__container">

    <h2 class="styled__title mb-2">Wall Controls: <span class="text-sm text-gray-400">{{ connected ? 'connected' : 'connecting...' }}</span></h2>
    <p class="styled__description">
      Control various parts of the wall in real time using these controls
    </p>

    <div
      v-if="connected"
      class="my-12 flex flex-col items-center"
      :class="viewChanging && 'opacity-60 pointer-events-none'"
    >

      <h3 class="styled__label mb-4">View Navigation</h3>
      <div class=" flex flex-wrap justify-center gap-4 mb-12">
        <busy-button
          v-for="view in availableViews"
          :key="view.name"
          class="styled__button"
          :class="currentView.name === view.name && 'primary'"
          @click="done => onView(view.name, done)"
        >{{ view.name }}</busy-button>
      </div>

      <h3 class="styled__label mb-4">Autoplay</h3>
      <label class="styled__label mb-12">
        <busy-toggle
          :value="autoplay"
          @change="(value, done) => onAutoplayChange(value, done)"
        />
      </label>

      <h3 class="styled__label mb-4">View Controls: {{ currentView.name }}</h3>

      <div
        v-if="currentView.name === 'gallery'"
        class="flex flex-col items-center"
      >
        <busy-button
          class="styled__button primary mb-9"
          @click="done => onStep(done)"
        >Next Gallery Step</busy-button>
        <h3 class="styled__label mb-4">Interactive Control</h3>
        <div class="w-52 h-52 p-9 box-content rounded-full grid grid-cols-3 gap-4 bg-gray-200 shadow-sm mb-8">
          <span></span>
          <button
            class="styled__button"
            :class="galleryControls && 'primary'"
            @click="onPan('down')"
          >
            <icon-arrow class="inline-block w-9 h-9 transform -rotate-180" />
          </button>
          <span></span>
          <button
            class="styled__button"
            :class="galleryControls && 'primary'"
            @click="onPan('right')"
          >
            <icon-arrow class="inline-block w-9 h-9 transform rotate-90" />
          </button>
          <label class="styled__label mt-2 text-center">
            <busy-toggle
              :value="galleryControls"
              @change="(value, done) => onGalleryEnable(value, done)"
            /><span class="inline-block mt-2">Activate</span>
          </label>
          <button
            class="styled__button"
            :class="galleryControls && 'primary'"
            @click="onPan('left')"
          >
            <icon-arrow class="inline-block w-9 h-9 transform -rotate-90" />
          </button>
          <span></span>
          <button
            class="styled__button"
            :class="galleryControls && 'primary'"
            @click="onPan('up')"
          >
            <icon-arrow class="inline-block w-9 h-9 transform" />
          </button>
          <span></span>
        </div>
        <h3 class="styled__label mb-4">Allow Gestures</h3>
        <label class="styled__label mb-12">
          <busy-toggle
            :value="allowGestures"
            @change="(value, done) => onAllowGestures(value, done)"
          />
        </label>
      </div>

      <div v-if="currentView.name === 'featured'" class="flex flex-col items-center">
        <busy-button
          class="styled__button primary mb-9"
          @click="done => onStep(done)"
        >Next Creator</busy-button>
      </div>

      <div v-if="currentView.name === 'billboard'" class="flex flex-col items-center">
        <busy-button
          class="styled__button primary mb-9"
          @click="done => onStep(done)"
        >Next Billboard</busy-button>
      </div>

      <div v-if="currentView.name === 'events'" class="flex flex-col items-center">
        <busy-button
          class="styled__button primary mb-9"
          @click="done => onStep(done)"
        >Next Event</busy-button>
      </div>
      
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BusyToggle from '@/components/BusyToggle'
import BusyButton from '@/components/BusyButton'
import IconArrow from '@/assets/svg/arrow.svg?inline'

export default {

  name: 'WallControls',

  components: {
    BusyToggle,
    BusyButton,
    IconArrow
  },

  data() {
    return {
      connected: false,
      autoplay: false,
      viewOrder: [],
      currentView: null,
      galleryControls: false,
      allowGestures: false,
      viewChanging: false,
      statusTimeout: null,
      responseTimeout: null,
      statusInterval: 1000
    }
  },

  computed: {
    ...mapState('socket', [
      'whenConnected'
    ]),

    availableViews() {
      return this.viewOrder.filter((view, index, self) =>
        index === self.findIndex(v => v.name === view.name) )
    }

  },

  methods: {

    ...mapActions('socket', [
      'sendAction'
    ]),

    onAutoplayChange(value, done) {
      this.sendAction({
        action: 'wall_interaction_autoplay',
        data: { value },
        complete: response => {
          this.autoplay = response.value
          done()
        }
      })
    },

    onGalleryEnable(value, done) {
      this.sendAction({
        action: 'wall_interaction_enable',
        data: { value },
        complete: response => {
          this.galleryControls = response.value
          done()
        }
      })
    },

    onAllowGestures(value, done) {
      this.sendAction({
        action: 'wall_gesture_enable',
        data: { value },
        complete: response => {
          this.allowGestures = response.value
          done()
        }
      })
    },

    onPan(direction) {
      this.sendAction({
        action: 'wall_interaction_pan',
        data: { direction }
      })
    },

    onView(name, done) {
      this.viewChanging = true
      this.sendAction({
        action: 'wall_interaction_view',
        data: { name },
        wait: 5000,
        complete: () => {
          this.viewChanging = false
          done()
        }
      })
    },

    onStep(done) {
      this.sendAction({
        action: 'wall_interaction_step',
        wait: 5000,
        complete: () => done()
      })
    },

    checkWallStatus() {
      this.sendAction({
        action: 'wall_interaction_status',
        complete: response => {
          this.connected = true
          this.autoplay = response.autoplay
          this.viewOrder = response.viewOrder
          this.currentView = response.currentView
          // this.galleryControls = response.currentView.userSelecting === true
          // this.gestureEnabled = response.currentView.gestureEnabled === true
          this.statusTimeout = setTimeout(() => this.checkWallStatus(), 3000)
        },
        timeout: () => {
          this.connected = false
          this.statusTimeout = setTimeout(() => this.checkWallStatus(), 3000)
        },
      })

    }

  },

  mounted() {
    this.whenConnected.then(() =>
      this.checkWallStatus())
  },

  beforeDestroy() {
    clearTimeout(this.statusTimeout)
    this.sendAction({
      action: 'wall_interaction_enable',
      data: { value: false }
    })
  }

}
</script>