<template>
  <main>
    <div class="md:flex md:items-start">
      <wall-controls class="md:w-full md:mr-0" />
      <icon-controls class="md:w-full" />
    </div>
  </main>
</template>

<script>
import WallControls from '@/components/WallControls'
import IconControls from '@/components/IconControls'

export default {
  name: 'Controls',
  components: {
    WallControls,
    IconControls
  },
}
</script>
