<template>
  <tr
    :class="{
      'bg-green-100': channel.approved,
      'bg-red-100': channel.approval_notes
    }"
  >
    <td>
      <div class="flex items-center text-left">
        <div
          class="flex-shrink-0 h-10 w-10 rounded-full bg-center bg-cover bg-gray-400 overflow-hidden"
          :style="{ backgroundImage: `url(${channel.api_data.snippet.thumbnails.default.url})`}"
        ></div>
        <div class="ml-4">
          <div class="font-medium text-gray-900">
            {{ count }}: {{ channel.api_data.snippet.title }}
          </div>
          <div
            class="text-gray-500 text-xs max-w-lg whitespace-nowrap overflow-hidden"
            v-for="video in videos"
            :key="video.id"
          >
            – {{ video.api_data.snippet.title }}
          </div>
        </div>
      </div>
    </td>
    <td>
      <div v-if="isAdmin">
        <busy-toggle
          :value="channel.approved"
          color="green-400"
          @change="(value, done) => onApprovedChange(value, done)"
        />
      </div>
      <div v-else
        class="text-gray-600 w-7 h-7 relative inline-block rounded-full border"
        :class="channel.approved && 'text-green-600 border-green-600'">
        <icon-checkmark v-if="channel.approved" class="absolute inset-0 m-1" />
      </div>
    </td>
    <td>
      <busy-toggle
        :value="channel.use_as_billboard"
        :disabled="!isAdmin && !Boolean(channel.feature_image)"
        @change="(value, done) => onBillboardChange(value, done)"
      />
    </td>
    <td>
      <div class="text-gray-600 w-7 h-7 relative inline-block rounded-full border-gray-300 border">
        <icon-checkmark v-if="channel.feature_image" class="absolute inset-0 m-1" />
      </div>
    </td>
    <td>
      <div class="flex gap-4 justify-end">
        <button v-if="!canApprove || isAdmin" class="styled__link" @click="onEdit">Edit</button>
        <button v-if="canApprove" class="styled__link" @click="onReview">Review</button>
      </div>
    </td>

  </tr>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import IconCheckmark from '@/assets/svg/checkmark.svg?inline'
import BusyToggle from '@/components/BusyToggle'
import PlaylistChannelModalEdit from './PlaylistChannelModalEdit'
import PlaylistChannelModalReview from './PlaylistChannelModalReview'

export default {

  name: 'PlaylistChannelRow',

  components: {
    IconCheckmark,
    BusyToggle,
    PlaylistChannelModalEdit,
    PlaylistChannelModalReview
  },

  props: {
    count: Number,
    channel: Object,
    videos: Array
  },

  computed: {
    ...mapState('auth', [
      'userId'
    ]),
    canApprove() {
      return ['admin', 'hwpark'].includes(this.userId)
    },
    isAdmin() {
      return this.userId === 'admin';
    }
  },

  methods: {

    ...mapActions('cms', [
      'postChannel',
      'postVideo'
    ]),

    onEdit() {
      this.showAppModal(PlaylistChannelModalEdit, {
        channel: this.channel,
        videos: this.videos
      })
    },

    onReview() {
      this.showAppModal(PlaylistChannelModalReview, {
        channel: this.channel,
        videos: this.videos
      })
    },

    onBillboardChange(value, done) {
      const data = { use_as_billboard: value }
      this.postChannel({ id: this.channel.id, data })
        .then(() => done())
    },

    onApprovedChange(value, done) {
      const data = { approved: value }
      this.postChannel({ id: this.channel.id, data })
        .then(() => done())
    }

  }

}
</script>