import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth.js'
import cms from './modules/cms.js'
import socket from './modules/socket.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { auth, cms, socket }
})

export { uploadToS3, removeFromS3 } from './modules/aws.js'
export { AppLocations, PlaylistTypes } from './modules/cms.js'