<template>
  <modal-wrapper max-width="lg" @click-outside="() => response(false)">
    <template v-slot:modal-header>
      <h1 class="font-semibold">{{ title }}</h1>
    </template>
    <p class="font-light">{{ message }}</p>
    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary ml-4"
        @click="done => response(true, done)"
      >Confirm</busy-button>
      <button
        class="styled__button"
        @click="() => response(false)"
      >Cancel</button>
    </template>
  </modal-wrapper>
</template>

<script>
import BusyButton from '@/components/BusyButton'
import { ModalWrapper } from '@/components/Modal'

export default {
  
  name: 'ConfirmModal',

  components: {
    ModalWrapper,
    BusyButton
  },

  props: {
    title: String,
    message: String,
    onResponse: Function
  },

  methods: {
    response(value, done) {
      new Promise(resolve => this.onResponse(value, resolve))
        .then(() => {
          if (done) done()
          this.closeAppModal()
        })
    }
  }

}
</script>