<template>
  <nav class="bg-gray-900">
    <div class="max-w-7xl mx-auto px-6">
      
      <div class="py-4 md:p-0 md:flex md:items-center md:justify-start md:h-24">
        <img class="h-8 w-32" :src="require('@/assets/youtube-logo.svg')" />
        <div class="mt-4 md:space-x-4 md:mt-0 md:ml-10" v-if="authenticated">
          <router-link
            v-for="route in routes"
            class="styled__nav-item"
            :class="{'active': route.path === $route.path }"
            :key="route.name"
            :to="route.path"
          >{{ route.name }}</router-link>
          <button class="styled__nav-item" @click="logout">Log Out</button>
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

  name: 'NavBar',

  computed: {

    ...mapState('auth', [
      'authenticated',
      'userId'
    ]),

    routes() {
      const routes = this.$router.options.routes.filter(item =>
        item.meta && item.meta.users && item.meta.users.includes(this.userId))
      return routes
    }
  },

  methods: {

    ...mapActions('auth', [
      'clearLogin'
    ]),

    logout() {
      this.clearLogin()
      this.$router.push('/login')
    }
  }

}
</script>