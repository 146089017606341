<template>
  <div class="styled__container">

    <h2 class="styled__title">Default Configuration – Wall</h2>

    <div class="my-6">
      <span class="block text-sm mb-2">Wall View Order Preset to Use</span>
      <loading-dots v-if="!wallPresets" class="inline-block h-8 w-12" opacity="0.25" stroke="#000" fill="#000" />
      <div v-else class="styled__input">
        <div
          class="flex my-4"
          v-for="item in wallPresets"
          :key="item.id"
        >
          <label class="flex cursor-pointer">
            <input
              class="styled__checkbox mr-4"
              type="radio"
              name="default_wall_preset"
              :checked="item.id === defaultWallPresetId"
              @input="e => onDefaultPresetInput(e, item.id)"
            />
            <span>{{ item.name }}</span>
          </label>
        </div>
      </div>
    </div>

    <div class="my-6">
      <span class="block text-sm mb-2">Creator Playlists To Use</span>
      <loading-dots v-if="!playlists" class="inline-block h-8 w-12" opacity="0.25" stroke="#000" fill="#000" />
      <p v-else-if="playlists.length === 0" class="text-gray-400 text-xs my-2">No playlists found</p>
      <div v-else class="styled__input">
        <div
          class="my-4"
          v-for="item in galleryPlaylists"
          :key="item.id"
        >
          <label class="flex cursor-pointer">
            <input
              class="styled__checkbox mr-4"
              type="checkbox"
              name="default_playlists"
              :checked="defaultPlaylistIds.includes(item.id)"
              @input="e => onDefaultPlaylistInput(e, item.id)"
            />
            <span>{{ item.title }}</span>
          </label>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import LoadingDots from '@/assets/svg/three-dots.svg?inline'

export default {

  name: 'WallDefaultConfig',
  
  components: {
    LoadingDots,
  },

  computed: {
    ...mapState('cms', [
      'playlists',
      'wallPresets'
    ]),
    ...mapGetters('cms', [
      'defaultWallPresetId',
      'defaultPlaylistIds'
    ]),
    galleryPlaylists() {
      return this.playlists?.filter(p => p.playlist_type === 'gallery') || []
    }
  },

  methods: {
    ...mapActions('cms', [
      'postDefaultWallPreset',
      'postDefaultPlaylist',
    ]),
    onDefaultPlaylistInput(e, id) {
      const input = e.target
      const value = e.target.checked
      input.disabled = true
      this.postDefaultPlaylist({ id, value, })
        .then(res => {
          input.disabled = false
          input.checked = this.playlists.find(p => p.id === id).is_default
        })
    },
    onDefaultPresetInput(e, id) {
      e.preventDefault()
      const input = e.target
      const value = e.target.checked
      input.disabled = true
      this.postDefaultWallPreset({ id, value, })
        .then(res => {
          input.disabled = false
          input.checked = true
        })
    },
  },

}
</script>