<template>
  <div class="fixed py-16 z-10 inset-0 flex items-start justify-center overflow-y-scroll">
    <div class="fixed z-0 inset-0" aria-hidden="true" @click="onClose">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <div class="block z-10 bg-white rounded-lg text-left overflow-hidden shadow-xl max-w-3xl w-full">
      <div class="bg-white p-8">
        <component :is="content" @close="onClose" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'ModalContainer',

  props: {
    content: Object
  },

  methods: {
    onClose() {
      this.closeRootModal()
    }
  },

  mounted() {
    document.body.style.overflow = 'hidden'
  },

  destroyed() {
    document.body.style.overflow = ''
  }

}
</script>