<template>
  <button
    class="relative"
    ref="button"
    :class="busy && 'animate-pulse pointer-events-none'"
    @click="onClick"
  >
    <span :style="{ visibility: busy ? 'hidden' : 'visible' }">
      <slot />
    </span>
    <loading-dots
      class="block absolute top-1/4 left-1/4 w-1/2 h-1/2"
      ref="dots"
      :style="{ visibility: busy ? 'visible' : 'hidden' }"
    />
  </button>
</template>

<script>
import LoadingDots from '@/assets/svg/three-dots.svg?inline'

export default {

  name: 'BusyButton',

  components: {
    LoadingDots
  },

  data() {
    return {
      busy: false
    }
  },

  methods: {
    onClick(e) {
      this.busy = true
      this.$emit('click', () => this.busy = false)
    }
  },

  mounted() {
    // use the color of the buttons foreground for the dots
    const { button, dots } = this.$refs
    const color = getComputedStyle(button).color
    dots.style.stroke = dots.style.fill = color
  }

}
</script>