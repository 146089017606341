import Vue from 'vue'
import axios from 'axios'

// use local django di-sofi cms if running development build
const url = process.env.NODE_ENV === 'development'
  ? `http://${location.hostname}:8000/api/youtube`
  : 'https://di-sofi.herokuapp.com/api/youtube'

// const url = 'https://di-sofi.herokuapp.com/api/youtube'
// const url = 'http://localhost:8000/api/youtube'

// some test playlist ids
// fashion: PLWZ01cUp987bnIk24kWmWPmJb2XLyakZe
// gaming: PLWZ01cUp987YS6DPvYftv38ykNCIDLB45

export const AppLocations = {
  wall: 'Wall',
  icon: 'Icon'
}

export const PlaylistTypes = {
  gallery: 'Gallery',
  shorts: 'Shorts'
}

export default {

  namespaced: true,

  state: {
    playlists: null,
    events: null,
    fullscreens: null,
    wallPresets: null,
    iconModes: null,
    scheduledWallConfigs: null,
    scheduledIconConfigs: null,
  },

  getters: {

    defaultWallPresetId: state => {
      if (!state.wallPresets)
        return null
      const preset = state.wallPresets.find(item => item.is_default)
      return preset ? preset.id : null
    },
    
    defaultPlaylistIds: state => {
      if (!state.playlists || state.playlists.length === 0)
        return []
      const playlists = state.playlists.filter(item => item.is_default)
      return playlists.length > 0 ? playlists.map(p => p.id) : []
    },

    defaultIconModeId: state => {
      if (!state.iconModes)
        return null
      const mode = state.iconModes.find(item => item.is_default)
      return mode ? mode.id : null
    },

  },

  actions: {

    /*
     * playlists...
     */
    fetchPlaylists({ state }) {
      console.log('[store.cms] fetchPlaylists...')
      state.playlists = null
      return axios
        .get(`${url}/playlist/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchPlaylists success, total:', results.length)
          results.forEach(playlist => playlist.videos.sort((a, b) => a.id - b.id))
          state.playlists = results.sort((a, b) => a.id - b.id)
        })
        .catch(err => console.log('[store.cms] fetchPlaylists error:', err))
    },

    importPlaylist({ state }, { id, type }) {
      console.log(`[store.cms] importPlaylist, ${type} id: ${id}...`)
      return axios
        .get(`${url}/import-playlist/?type=${type}&id=${id}`)
        .then(res => {
          console.log('[store.cms] importPlaylist success:', res)
          if (res.data.error) {
            throw new Error(res.data.error)
          } else  {
            state.playlists = state.playlists.filter(item => item.id !== res.data.id)
            state.playlists.push(res.data)
          }
        })
        .catch(err => {
          console.log('[store.cms] importPlaylist error:', err)
          throw err
        })
    },

    postDefaultPlaylist({ state }, { id, value }) {
      console.log(`[store.cms] postDefaultPlaylist, id: ${id}, value: ${value}`)
      return axios
        .patch(`${url}/playlist/${id}/`, { is_default: value })
        .then(res => {
          console.log('[store.cms] postDefaultPlaylist success:', res)
          const index = state.playlists.findIndex(item => item.id === res.data.id)
          Vue.set(state.playlists, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postDefaultPlaylist error:', err))
    },

    deletePlaylist({ state }, { id }) {
      console.log('[store.cms] deletePlaylist...')
      return axios
        .delete(`${url}/playlist/${id}/`)
        .then(() => {
          console.log('[store.cms] deletePlaylist success')
          state.playlists = state.playlists.filter(item => item.id !== id)
        })
        .catch(err => console.warn('[store.cms] deletePlaylist error:', err))
    },

    /*
     * channels...
     */
    postChannel({ state }, { id, data }) {

      console.log(`[store.cms] postChannel, id: ${id}...`)

      let formData

      if (data.feature_image) {
        formData = new FormData()
        formData.append('feature_image', data.feature_image)
        delete data.feature_image
      }

      const promise = id ? // post/patch based on if id is passed in
        axios.patch(`${url}/channel/${id}/`, data) :
        axios.post(`${url}/channel/`, data)

      return promise
        .then(res => {
          if (formData) return axios
            .patch(`${url}/channel/${res.data.id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
          else return res
        })
        .then(res => {
          console.log('[store.cms] postChannel success:', res)
          const playlist = state.playlists.find(item => item.id === res.data.playlist)
          const index = playlist.channels.findIndex(item => item.id === res.data.id)
          Vue.set(playlist.channels, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postChannel error:', err))
      
    },

    /*
     * videos...
     */
    postVideo({ state }, { id, data }) {
      console.log(`[store.cms] postVideo, id: ${id}...`, data)
      return axios
        .patch(`${url}/video/${id}/`, data)
        .then(res => {
          console.log('[store.cms] postVideo success:', res)
          const playlist = state.playlists.find(item => item.id === res.data.playlist)
          const index = playlist.videos.findIndex(item => item.id === res.data.id)
          Vue.set(playlist.videos, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postVideo error:', err))
    },

    /*
     * events...
     */
    fetchEvents({ state }) {
      console.log('[store.cms] fetchEvents...')
      state.events = null
      return axios
        .get(`${url}/event/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchEvents success, found:', results.length)
          state.events = results
        })
        .catch(err => console.log('[store.cms] fetchEvents error:', err))
    },

    postEvent({ state }, { id, data }) {

      console.log(`[store.cms] postEvent, id: ${id}, data:`, data)

      let formData

      if (data.feature_image || data.thumbnail_image) {
        formData = new FormData()
        if (data.feature_image) {
          formData.append('feature_image', data.feature_image)
          delete data.feature_image
        }
        if (data.thumbnail_image) {
          formData.append('thumbnail_image', data.thumbnail_image)
          delete data.thumbnail_image
        }
      }

      const promise = id ? // post/patch based on if id is passed in
        axios.patch(`${url}/event/${id}/`, data) :
        axios.post(`${url}/event/`, data)

      return promise
        .then(res => {
          if (formData) return axios
            .patch(`${url}/event/${res.data.id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
          else return res
        })
        .then(res => {
          console.log('[store.cms] postEvent success:', res)
          const index = state.events.findIndex(item => item.id === res.data.id)
          if (index >= 0) Vue.set(state.events, index, res.data)
          else state.events.push(res.data)
        })
        .catch(err => console.warn('[store.cms] postEvent error:', err))
      
    },

    deleteEvent({ state }, { id }) {
      console.log(`[store.cms] deleteEvent, id: ${id}...`)
      return axios
        .delete(`${url}/event/${id}/`)
        .then(() => {
          console.log('[store.cms] deleteEvent success')
          state.events = state.events.filter(item => item.id !== id)
        })
        .catch(err => console.warn('[store.cms] deleteEvent error:', err))
    },

    postEventDate({ state }, { id, data }) {
      console.log(`[store.cms] postEventDate, id: ${id}, data:`, data)
      return axios.patch(`${url}/event-date/${id}/`, data)
        .then(res => {
          console.log('[store.cms] postEventDate success:', res)
          const event = state.events.find(item => item.id === res.data.event)
          const index = event.event_dates.findIndex(item => item.id === res.data.id)
          Vue.set(event.event_dates, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postEventDate error:', err))
    },

    /*
     * fullscreens...
     */
    fetchFullscreens({ state }) {
      console.log('[store.cms] fetchFullscreens...')
      state.fullscreens = null
      return axios
        .get(`${url}/fullscreen/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchFullscreens success, found:', results.length)
          state.fullscreens = results
        })
        .catch(err => console.log('[store.cms] fetchFullscreens error:', err))
    },

    postFullscreen({ state }, { id, data }) {

      console.log(`[store.cms] postFullscreen, id: ${id}, data:`, data)

      let formData

      if (data.feature_image) {
        formData = new FormData()
        formData.append('feature_image', data.feature_image)
        delete data.feature_image
      }

      const promise = id ? // post/patch based on if id is passed in
        axios.patch(`${url}/fullscreen/${id}/`, data) :
        axios.post(`${url}/fullscreen/`, data)

      return promise
        .then(res => {
          if (formData) return axios
            .patch(`${url}/fullscreen/${res.data.id}/`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
          else return res
        })
        .then(res => {
          console.log('[store.cms] postFullscreen success:', res)
          const index = state.fullscreens.findIndex(item => item.id === res.data.id)
          if (index >= 0) Vue.set(state.fullscreens, index, res.data)
          else state.fullscreens.push(res.data)
        })
        .catch(err => console.warn('[store.cms] postFullscreen error:', err))
    },

    deleteFullscreen({ state }, { id }) {
      console.log(`[store.cms] deleteFullscreen, id: ${id}...`)
      return axios
        .delete(`${url}/fullscreen/${id}/`)
        .then(() => {
          console.log('[store.cms] deleteFullscreen success')
          state.fullscreens = state.fullscreens.filter(item => item.id !== id)
        })
        .catch(err => console.warn('[store.cms] deleteFullscreen error:', err))
    },

    /*
     * wall configs...
     */
    fetchWallPresets({ state }) {
      console.log('[store.cms] fetchWallPresets...')
      state.wallPresets = null
      return axios
        .get(`${url}/wall-preset/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchWallPresets success, total:', results.length)
          state.wallPresets = results
        })
        .catch(err => console.log('[store.cms] fetchWallPresets error:', err))
    },

    postDefaultWallPreset({ state }, { id, value }) {
      console.log(`[store.cms] postDefaultWallPreset, id: ${id}, value: ${value}`)
      return axios
        .patch(`${url}/wall-preset/${id}/`, { is_default: value })
        .then(res => {
          console.log('[store.cms] postDefaultWallPreset success:', res)
          state.wallPresets.forEach(item => item.is_default = value)
          const index = state.wallPresets.findIndex(item => item.id === res.data.id)
          Vue.set(state.wallPresets, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postDefaultWallPreset error:', err))
    },

    /*
     * wall schedule...
     */
    fetchScheduledWallConfigs({ state }) {
      console.log('[store.cms] fetchScheduledWallConfigs...')
      state.scheduledWallConfigs = null
      return axios
        .get(`${url}/scheduled-wall-config/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchScheduledWallConfigs success, found:', results.length)
          state.scheduledWallConfigs = results
        })
        .catch(err => console.log('[store.cms] fetchScheduledWallConfigs error:', err))
    },

    postScheduledWallConfig({ state }, { id, data }) {

      console.log(`[store.cms] postScheduledWallConfig, id: ${id}, data:`, data)

      const promise = id ? // post/patch based on if id is passed in
        axios.patch(`${url}/scheduled-wall-config/${id}/`, data) :
        axios.post(`${url}/scheduled-wall-config/`, data)

      return promise
        .then(res => {
          console.log('[store.cms] postScheduledWallConfig success:', res)
          const index = state.scheduledWallConfigs.findIndex(item => item.id === res.data.id)
          if (index >= 0) Vue.set(state.scheduledWallConfigs, index, res.data)
          else state.scheduledWallConfigs.push(res.data)
        })
        .catch(err => console.warn('[store.cms] postScheduledWallConfig error:', err))
    },

    deleteScheduledWallConfig({ state }, { id }) {
      console.log(`[store.cms] deleteScheduledWallConfig, id: ${id}...`)
      return axios
        .delete(`${url}/scheduled-wall-config/${id}/`)
        .then(() => {
          console.log('[store.cms] deleteScheduledWallConfig success')
          state.scheduledWallConfigs = state.scheduledWallConfigs.filter(item => item.id !== id)
        })
        .catch(err => console.warn('[store.cms] deleteScheduledWallConfig error:', err))
    },

    /*
     * icon modes...
     */
    fetchIconModes({ state }) {
      console.log('[store.cms] fetchIconModes...')
      state.iconModes = null
      return axios
        .get(`${url}/icon-mode/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchIconModes success, total:', results.length)
          state.iconModes = results
        })
        .catch(err => console.log('[store.cms] fetchIconModes error:', err))
    },

    postDefaultIconMode({ state }, { id, value }) {
      console.log(`[store.cms] postDefaultIconMode, id: ${id}, value: ${value}`)
      return axios
        .patch(`${url}/icon-mode/${id}/`, { is_default: value })
        .then(res => {
          console.log('[store.cms] postDefaultIconMode success:', res)
          state.iconModes.forEach(item => item.is_default = value)
          const index = state.iconModes.findIndex(item => item.id === res.data.id)
          Vue.set(state.iconModes, index, res.data)
        })
        .catch(err => console.warn('[store.cms] postDefaultIconMode error:', err))
    },

    /*
     * icon schedule...
     */
    fetchScheduledIconConfigs({ state }) {
      console.log('[store.cms] fetchScheduledIconConfigs...')
      state.scheduledIconConfigs = null
      return axios
        .get(`${url}/scheduled-icon-config/`)
        .then(res => {
          const results = res.data
          console.log('[store.cms] fetchScheduledIconConfigs success, found:', results.length)
          state.scheduledIconConfigs = results
        })
        .catch(err => console.log('[store.cms] fetchScheduledIconConfigs error:', err))
    },

    postScheduledIconConfig({ state }, { id, data }) {

      console.log(`[store.cms] postScheduledIconConfig, id: ${id}, data:`, data)

      const promise = id ? // post/patch based on if id is passed in
        axios.patch(`${url}/scheduled-icon-config/${id}/`, data) :
        axios.post(`${url}/scheduled-icon-config/`, data)

      return promise
        .then(res => {
          console.log('[store.cms] postScheduledIconConfig success:', res)
          const index = state.scheduledIconConfigs.findIndex(item => item.id === res.data.id)
          if (index >= 0) Vue.set(state.scheduledIconConfigs, index, res.data)
          else state.scheduledIconConfigs.push(res.data)
        })
        .catch(err => console.warn('[store.cms] postScheduledIconConfig error:', err))
    },

    deleteScheduledIconConfig({ state }, { id }) {
      console.log(`[store.cms] deleteScheduledIconConfig, id: ${id}...`)
      return axios
        .delete(`${url}/scheduled-icon-config/${id}/`)
        .then(() => {
          console.log('[store.cms] deleteScheduledIconConfig success')
          state.scheduledIconConfigs = state.scheduledIconConfigs.filter(item => item.id !== id)
        })
        .catch(err => console.warn('[store.cms] deleteScheduledIconConfig error:', err))
    },

  },
  
}