import { post } from 'axios'

const host = process.env.NODE_ENV === 'development'
  ? `${location.protocol}//${location.hostname}:3000`
  : `${location.protocol}//${location.host}`

export default {

  namespaced: true,

  state: {
    authenticated: false,
    userId: '',
  },

  actions: {

    submitLogin({ state }, { user, password }) {

      console.log(`[Login] checking user ${user}...`)

      return post(`${host}/login`, { user, password })
        .then(res => {
          localStorage.setItem('user', res.data.user)
          localStorage.setItem('jwt', res.data.token)
        })
        .catch(err => console.log(err))

    },

    clearLogin({ state }) {
      localStorage.clear()
      state.authenticated = false
      state.userId = ''
    },

    checkAuth({ state }) {
      console.log(`[Login] checking user token...`)
      const token = localStorage.getItem('jwt')
      if (token !== null) {
        const storedUser = localStorage.getItem('user')
        state.authenticated = true
        state.userId = storedUser
      }
    }

  }

}