import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Schedule from '@/views/Schedule'
import Playlists from '@/views/Playlists'
import Events from '@/views/Events'
import Controls from '@/views/Controls'
import Sync from '@/views/Sync'
import Fullscreens from '@/views/Fullscreens'

import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/schedule',
      name: 'Schedule',
      component: Schedule,
      meta: {
        users: [ 'admin', 'hwpark' ]
      }
    },
    {
      path: '/playlists',
      name: 'Playlists',
      component: Playlists,
      meta: {
        users: [ 'admin', 'hwpark', 'youtube' ]
      }
    },
    {
      path: '/events',
      name: 'Events',
      component: Events,
      meta: {
        users: [ 'admin', 'hwpark' ]
      }
    },
    {
      path: '/controls',
      name: 'Controls',
      component: Controls,
      meta: {
        users: [ 'admin', 'hwpark', 'youtube' ]
      }
    },
    {
      path: '/fullscreens',
      name: 'Fullscreens',
      component: Fullscreens,
      meta: {
        users: [ 'admin', 'hwpark', 'youtube' ]
      }
    },
    {
      path: '/sync',
      name: 'Sync',
      component: Sync,
      meta: {
        users: [ 'admin', 'hwpark' ]
      }
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.users)) {
    if (localStorage.getItem('jwt') === null) {
      console.log('[router] no token')
      next('/login')
    } else {
      console.log('[router] has token!')
      store.dispatch('auth/checkAuth')
      next()
    }
  } else {
    console.log('[router] route doesnt require user auth')
    next()
  }
})

export default router
