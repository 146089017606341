<template>
  <modal-wrapper @click-outside="onClose">

    <template v-slot:modal-header>
      <h2><strong>Edit Creator:</strong></h2>
    </template>

    <div class="my-6 bg-gray-100 rounded px-6 py-4 border">
      <div class="flex items-center">
        <div
          class="flex-shrink-0 h-16 w-16 rounded-full bg-center bg-cover bg-gray-400 overflow-hidden"
          :style="{ backgroundImage: `url(${channel.api_data.snippet.thumbnails.default.url})`}"
        ></div>
        <div class="ml-4">
          <p class="font-medium text-gray-900">
            {{ channel.api_data.snippet.title }}
          </p>
          <p class="text-gray-500 text-xs">
            {{ channel.api_data.statistics.subscriberCount | numberCompact }} Subscribers
          </p>
        </div>
      </div>
      <p class="text-sm text-gray-600 font-light mt-2">
        {{ channel.api_data.snippet.description | descriptionEllipsis }}
      </p>
    </div>

    <div
      class="my-6 bg-red-100 rounded px-6 py-4 border border-red-600"
      v-if="notes"
    >
      <h3 class="text-red-600 uppercase text-xs mb-2">Approval Notes</h3>
      <p>{{ notes }}</p>
    </div>

    <modal-section
      title="Custom Blurb"
      description="Optionally enter a custom gallery blurb that will override the content creator's provided description. Max 280 characters."
    >
      <textarea
        class="styled__input w-full h-40"
        maxlength="280"
        placeholder="Enter description..."
        v-model="description"
      ></textarea>
    </modal-section>

    <modal-section
      title="Feature Image"
      description="The creator's poster image that will be displayed during the 'Featured Creators' view mode. Image should be 16:9 @ 1920x1080."
    >
      <figure class="styled__figure w-full">
        <div class="aspect-w-16 aspect-h-9">
          <img
            v-if="image.preview"
            :src="image.preview"
            :alt="channel.api_data.snippet.title"
          />
        </div>
        <figcaption>1920x1080 (16:9)</figcaption>
      </figure>
      <div class="flex w-full justify-between mt-4">
        <input
          class="text-sm"
          type="file"
          ref="imageInput"
          @change="e => onImageChange(e)"
        />
        <button
          v-if="image.preview"
          class="styled__button"
          @click="onImageRemove"
        >Remove</button>
      </div>
    </modal-section>

    <modal-section
      title="Videos in Playlist"
      description="Videos this creator has in the playlist that will be used in the 'Gallery' view mode. Only the first 30s of these videos will be used."
    >
      <div
        class="relative mb-6"
        v-for="(video, index) in videos"
        :key="video.video_id"
      >
        <span class="absolute -left-4 -top-4 rounded-full bg-red-600 text-white w-8 h-8 text-md pt-1 text-center z-10">{{ index+1 }}</span>
        <div class="aspect-w-16 aspect-h-9 overflow-visible bg-gray-200 last:mb-0 mb-4 z-0">
          <iframe
            :src="`https://www.youtube.com/embed/${video.video_id}`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p class="text-xs text-gray-400 -mt-2 mb-9">
          Views: {{ video.api_data.statistics.viewCount | numberCompact }}, 
          Likes: {{ video.api_data.statistics.likeCount | numberCompact }}
        </p>
      </div>
    </modal-section>

    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary ml-4"
        @click="done => onSave(done)"
      >Save</busy-button>
      <button
        class="styled__button"
        @click="onClose"
      >Cancel</button>
    </template>

  </modal-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import { ModalWrapper, ModalSection } from '@/components/Modal'
import BusyButton from '@/components/BusyButton'

export default {

  name: 'PlaylistChannelModalEdit',

  components: {
    ModalWrapper,
    ModalSection,
    BusyButton
  },

  props: {
    channel: Object,
    videos: Array
  },

  data() {
    return {
      description: this.channel.custom_blurb,
      notes: this.channel.approval_notes,
      image: {
        preview: this.channel.feature_image,
        file: null
      }
    };
  },

  methods: {

    ...mapActions('cms', [
      'postChannel',
    ]),

    onImageChange(e) {
      const input = this.$refs.imageInput
      const files = input.files
      if (files.length > 0) {
        this.image.file = files[0]
        const reader = new FileReader()
        reader.onload = e => this.image.preview = e.target.result
        reader.readAsDataURL(this.image.file)
      }
    },

    onImageRemove() {
      const input = this.$refs.imageInput
      input.value = null
      this.image = {
        preview: null,
        file: null
      }
    },

    onClose() {
      this.$emit('close');
    },

    onSave(done) {

      const data = {
        custom_blurb: this.description,
        approved: false
      }

      if (this.image.file) {
        // if file selected, add to data
        data.feature_image = this.image.file
      } else if (!this.image.preview) {
        // if preview is missing, add null to data so its removed
        data.feature_image = null
        data.use_as_billboard = false
      }

      this.postChannel({
        id: this.channel.id,
        data
      }).then(() => {
        done()
        this.$emit('close')
      })
      
    }

  }

}
</script>