<template>
  <div class="styled__container pb-0">

    <h2 class="styled__title mb-4">Shared Voice Events</h2>
    
    <div class="bg-gray-200 -mx-6 p-6">

      <p class="text-xs text-gray-500 mb-4">* All times are PST (America/Los Angeles)</p>

      <loading-dots v-if="!events" class="block h-8 w-12 mx-auto my-4" />
      <p v-else-if="events.length === 0" class="text-gray-400 text-center my-4">No events found.</p>
      <div v-else class="overflow-x-auto">
        <table class="styled__table rounded overflow-hidden" style="min-width:768px">
          <thead>
            <tr>
              <th class="text-left">Event</th>
              <th>Dates</th>
              <th>Sold Out</th>
              <th>Use As Billboard</th>
              <th><span class="sr-only">Edit</span></th>
            </tr>
          </thead>
          <tbody>
            <event-row
              v-for="event in sorted"
              :key="event.id"
              :event="event"
            />
          </tbody>
        </table>
      </div>

      <div class="flex justify-end">
        <button
          class="styled__button primary mt-6"
          @click="onCreate"
        >Add New</button>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingDots from '@/assets/svg/three-dots.svg?inline'
import BusyButton from '@/components/BusyButton'
import EventRow from './EventRow'
import EventModal from './EventModal'
import { DateTime } from 'luxon'

export default {

  name: 'EventsManager',

  components: {
    LoadingDots,
    BusyButton,
    EventRow
  },

  computed: {
    ...mapState('cms', [
      'events'
    ]),
    sorted() {
      return this.events.sort((a, b) => {
        const firstA = a.event_dates[0] || 0
        const firstB = b.event_dates[0] || 0
        return new Date(firstA.date) - new Date(firstB.date)
      })
    },
  },

  methods: {

    ...mapActions('cms', [
      'fetchEvents'
    ]),

    onCreate() {

      const date = DateTime.now()
        .setZone('America/Los_Angeles')
        .set({ minute: 0, second: 0, millisecond: 0 })
        .plus({ hour: 1 })

      this.showAppModal(EventModal, {
        event: {
          event_title: 'New Event',
          event_dates: [{
            date: date.toISO(),
            sold_out: false
          }]
        }
      })
      
    }

  }

}
</script>