<template>
  <modal-wrapper @click-outside="onClose">

    <template v-slot:modal-header>
      <h2><strong>Edit Event: </strong>{{ title }}</h2>
    </template>

    <modal-section
      title="Event Title"
      description="Name of the event"
    >
      <input
        class="styled__input w-full"
        type="text"
        v-model="title"
      />
    </modal-section>

    <modal-section
      title="Event Dates"
      description="Dates for when the event is happening in PST time, (America/Los_Angeles)"
    >
      <div
        v-for="(item, index) in dates"
        :key="`${index}_${item.id}`"
        class="flex mb-4"
      >
        <input
          class="styled__input w-full"
          type="datetime-local"
          :value="item.date.slice(0, 19)"
          @change="e => item.date = e.target.value"
        />
        <button
          class="text-xs uppercase text-red-600 ml-4"
          @click="e => onRemoveDate(item)"
        >remove</button>
      </div>
      <button
        class="styled__button sm:float-right"
        @click="e => onAddDate()"
      >Add Date</button>
    </modal-section>

    <modal-section
      title="Tickets Url"
      description="Url to buy tickets for the event. A QR code and pretty url will be automatically generated and displayed from this"
    >
      <input
        class="styled__input w-full"
        placeholder="http://"
        type="text"
        v-model="url"
      />
      <p class="text-xs mt-1 text-gray-400">{{ url | urlPretty }}</p>
    </modal-section>

    <modal-section
      title="Channel Id"
      description="(optional) YouTube channel ID to link to. A QR code and pretty url will be automatically generated and displyed from this"
    >
      <input
        class="styled__input w-full rounded-bl-none rounded-br-none"
        placeholder="channelId..."
        type="text"
        v-model="channelId"
      />
      <div
        class="bg-gray-50 rounded p-4 border border-t-0 rounded-tl-none rounded-tr-none"
      >
        <div class="flex items-center" v-if="channel && channelId === channel.id">
          <div
            class="flex-shrink-0 h-16 w-16 rounded-full bg-center bg-cover bg-gray-400 overflow-hidden"
            :style="{ backgroundImage: `url(${channel.snippet.thumbnails.default.url})`}"
          ></div>
          <div class="ml-4">
            <p class="font-medium text-gray-900">
              {{ channel.snippet.title }}
            </p>
          </div>
        </div>
        <p v-else class="text-xs mt-1 text-gray-400">Channel info will appear here once saved.</p>
      </div>
    </modal-section>

    <modal-section
      title="Feature Image"
      description="The event's poster image to display when cycling through the events in shared voice mode. Image should be 1920x1080 (16:9)"
    >
      <figure class="styled__figure w-full">
        <div class="aspect-w-16 aspect-h-9">
          <img
            v-if="feature.preview"
            :src="feature.preview"
            :alt="event.event_title"
          />
        </div>
        <figcaption>1920x1080 (16:9)</figcaption>
      </figure>
      <div class="flex w-full items-center justify-between mt-4">
        <input
          class="text-sm"
          type="file"
          ref="featureInput"
          @change="onFileChange('featureInput', 'feature')"
        />
        <button
          v-if="feature.preview"
          class="styled__button"
          @click="onFileRemove('featureInput', 'feature')"
        >Remove</button>
      </div>
    </modal-section>

    <modal-section
      title="Thumbnail Image"
      description="The event's smaller thumb image to display next to it's name when cycling through the events in shared voice mode. Image should be 1:1 @ 240x240"
    >
      <figure class="styled__figure w-1/2">
        <div class="aspect-w-1 aspect-h-1">
          <img
            v-if="thumb.preview"
            :src="thumb.preview"
            :alt="event.event_title"
          />
        </div>
        <figcaption>240x240 (1:1)</figcaption>
      </figure>
      <div class="flex w-full items-center justify-between mt-4">
        <input
          class="text-sm"
          type="file"
          ref="thumbInput"
          @change="onFileChange('thumbInput', 'thumb')"
        />
        <button
          v-if="thumb.preview"
          class="styled__button"
          @click="onFileRemove('thumbInput', 'thumb')"
        >Remove</button>
      </div>
    </modal-section>

    <template v-slot:modal-footer>
      <busy-button
        class="styled__button primary ml-4"
        @click="done => onSave(done)"
      >Save</busy-button>
      <button
        class="styled__button"
        @click="onClose"
      >Cancel</button>
    </template>

  </modal-wrapper>
</template>

<script>
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { ModalWrapper, ModalSection } from '@/components/Modal'
import IconRemove from '@/assets/svg/close.svg?inline'
import BusyButton from '@/components/BusyButton'
import BusyToggle from '@/components/BusyToggle'

export default {

  name: 'EventModal',

  components: {
    ModalWrapper,
    ModalSection,
    IconRemove,
    BusyButton,
    BusyToggle
  },

  props: {
    event: Object
  },

  data() {
    return {
      title: this.event.event_title,
      dates: [
        // use copy of date data so it doesn't write changes unless saved
        ...this.event.event_dates.map(item => Object.assign({}, item))
      ],
      url: this.event.tickets_url,
      channelId: this.event.channel_id,
      channel: this.event.channel_data,
      feature: {
        preview: this.event.feature_image,
        file: null
      },
      thumb: {
        preview: this.event.thumbnail_image,
        file: null
      }
    };
  },

  methods: {

    ...mapActions('cms', [
      'postEvent',
      'createEvent'
    ]),

    onRemoveDate(item) {
      this.dates = this.dates.filter(date => date !== item)
    },

    onAddDate() {
      const date = DateTime.now()
        .setZone('America/Los_Angeles')
        .set({ minute: 0, second: 0, millisecond: 0 })
        .plus({ hour: 1 })
      this.dates.push({
        sold_out: false,
        date: date.toISO()
      })
    },

    onFileChange(ref, prop) {
      const files = this.$refs[ref].files
      const obj = this[prop]
      if (files.length > 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = e => obj.preview = e.target.result
        reader.readAsDataURL(file)
        obj.file = file
      }
    },

    onFileRemove(ref, prop) {
      this.$refs[ref].value = null
      this[prop] = {
        preview: null,
        file: null
      }
    },

    onSave(done) {

      const data = {
        event_title: this.title,
        event_dates: this.dates,
        channel_id: this.channelId,
        tickets_url: this.url
      }

      if (this.feature.file) {
        // if file selected, add to data
        data.feature_image = this.feature.file
      } else if (!this.feature.preview) {
        // if preview is missing, add null to data so its removed
        data.feature_image = null
      }

      if (this.thumb.file) {
        // if file selected, add to data
        data.thumbnail_image = this.thumb.file
      } else if (!this.thumb.preview) {
        // if preview is missing, add null to data so its removed
        data.thumbnail_image = null
      }

      this.postEvent({
        id: this.event.id,
        data
      }).then(() => {
        done()
        this.$emit('close')
      })

    },

    onClose() {
      this.$emit('close')
    }

  }

}
</script>