<template>
  <main>
    <div class="sm:flex">
      <wall-default-config class="sm:w-full" />
      <icon-default-config class="sm:w-full" />
    </div>
    <schedule-manager location="wall" :scheduled-configs="scheduledWallConfigs" />
    <schedule-manager location="icon" :scheduled-configs="scheduledIconConfigs" />
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import WallDefaultConfig from '@/components/DefaultConfigs/WallDefaultConfig'
import IconDefaultConfig from '@/components/DefaultConfigs/IconDefaultConfig'
import ScheduleManager from '@/components/ScheduleManager'

export default {

  name: 'Schedule',

  components: {
    WallDefaultConfig,
    IconDefaultConfig,
    ScheduleManager,
  },

  computed: {
    ...mapState('cms', [
      'scheduledWallConfigs',
      'scheduledIconConfigs',
    ])
  },

  methods: {
    ...mapActions('cms', [
      'fetchPlaylists',
      'fetchWallPresets',
      'fetchIconModes',
      'fetchScheduledWallConfigs',
      'fetchScheduledIconConfigs'
    ])
  },

  async created() {
    this.fetchPlaylists()
    this.fetchWallPresets()
    this.fetchIconModes()
    this.fetchScheduledWallConfigs()
    this.fetchScheduledIconConfigs()
  }

}
</script>
