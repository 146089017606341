<template>
  <header class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-6 py-8">
      <h1 class="text-3xl font-bold text-gray-900">{{ title }}</h1>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: String
  }
}
</script>