const protocol = location.protocol.replace('http', 'ws')

const host = process.env.NODE_ENV === 'development'
  ? `${protocol}//${location.hostname}:3000`
  : `${protocol}//${location.host}`

// const host = 'wss://di-youtube-admin.herokuapp.com'

let socket, handlers = []

function handleResponseMessage(event) {

  const { action, data } = JSON.parse(event.data);

  // find the correct handlers to call if any responses are registered
  const listening = handlers.filter(h => action.includes(h.action));

  if (listening.length > 0) {
    for (let handler of listening) {
      handler.keepAlive()
      const type = action.split(handler.action+'_')[1];
      const cb = handler[type]
      if (cb)
        cb(data)
      if (type === 'complete') {
        handlers = handlers.filter(h => h !== handler)
        clearTimeout(handler.timeoutId)
      }
    }
  }
}

export default {

  namespaced: true,

  state: {
    connected: false,
    whenConnected: null
  },

  actions: {

    connect({ state }) {

      console.log(`[store.socket] connecting to: ${host}`)

      state.whenConnected = new Promise(resolve => {

        socket = new WebSocket(host)

        socket.addEventListener('open', () => {
          state.connected = true
          console.log('[store.socket] connected:', state.connected)
          resolve()
        })

        socket.addEventListener('message', handleResponseMessage)
        
      })
      
    },

    disconnect({ state }) {

      console.log('[store.socket] disconnecting')

      if (socket) {
        socket.close()
        socket = null
      }

      state.connected = false
      
    },

    sendAction({ state }, props) {

      let { action, data, wait, update, complete, timeout } = props
      const message = JSON.stringify({ action, data })

      if (wait === undefined)
        wait = 1000*3 // 3 second timeout default

      const handler = {
        action,
        update,
        complete,
        timeout,
        timeoutId: null,
        keepAlive() {
          if (wait <= 0)
            return;
          clearTimeout(this.timeoutId)
          this.timeoutId = setTimeout(() => {
            console.log(`Action ${this.action} timeout`)
            if (this.timeout)
              this.timeout(`Action ${this.action} timeout`)
            handlers = handlers.filter(h => h !== this)
          }, wait) // times out after 30 seconds by default
        }
      }

      handler.keepAlive()
      handlers.push(handler)
      socket.send(message)

    },

  }

}