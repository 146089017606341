<template>
  <div class="styled__container pb-0">

    <h2 class="styled__title mb-4">Scheduled Configurations – {{ locationLabel }}</h2>

    <div class="bg-gray-200 -mx-6 p-6">

      <p class="text-xs text-gray-500 mb-4">* All times are PST (America/Los Angeles)</p>

      <loading-dots v-if="!scheduledConfigs" class="block h-8 w-12 mx-auto my-4" />
      <p v-else-if="scheduledConfigs.length === 0" class="text-gray-400 text-center my-4">No configurations scheduled.</p>
      <div v-else class="overflow-x-auto">
        <table class="styled__table rounded-4 overflow-hidden" style="min-width: 768px">

          <template v-if="location === 'wall'">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Creator Playlists</th>
                <th>Wall ViewMode</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th><span class="sr-only">Edit</span></th>
              </tr>
            </thead>
            <tbody>
              <wall-schedule-row
                v-for="schedule in sorted"
                :key="schedule.id"
                :schedule="schedule"
              />
            </tbody>
          </template>

          <template v-if="location === 'icon'">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th>Icon Mode</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th><span class="sr-only">Edit</span></th>
              </tr>
            </thead>
            <tbody>
              <icon-schedule-row
                v-for="schedule in sorted"
                :key="schedule.id"
                :schedule="schedule"
              />
            </tbody>
          </template>

        </table>
      </div>

      <div class="flex justify-end">
        <button
          class="styled__button primary mt-6"
          @click="onCreate"
        >Add New</button>
      </div>

    </div>

  </div>
</template>

<script>
import { AppLocations } from '@/store'
import LoadingDots from '@/assets/svg/three-dots.svg?inline'
import BusyButton from '@/components/BusyButton'
import WallScheduleRow from './WallScheduleRow'
import WallScheduleModal from './WallScheduleModal'
import IconScheduleRow from './IconScheduleRow'
import IconScheduleModal from './IconScheduleModal'
import { DateTime } from 'luxon'

export default {

  name: 'ScheduleManager',

  components: {
    LoadingDots,
    BusyButton,
    WallScheduleRow,
    IconScheduleRow,
  },

  props: {
    location: String,
    scheduledConfigs: Array,
  },

  computed: {

    sorted() {
      return this.scheduledConfigs.sort((a, b) =>
        DateTime.fromISO(a.datetime_start) - DateTime.fromISO(b.datetime_start))
    },

    locationLabel() {
      return AppLocations[this.location]
    }

  },

  methods: {

    onCreate() {

      const modal = {
        wall: WallScheduleModal,
        icon: IconScheduleModal
      }[this.location]

      const start = DateTime.now()
        .setZone('America/Los_Angeles')
        .set({ minute: 0, second: 0, millisecond: 0 })
        .plus({ hour: 1 })

      this.showAppModal(modal, {
        // i suppose this will work since they both share these fields
        schedule: {
          name: 'New Scheduled Config',
          datetime_start: start.toISO(),
          datetime_end: start.plus({ hour: 1 }).toISO(),
        }
      })

    }

  }

}
</script>