<template>
  <main>
    <fullscreens-manager location="wall" />
    <fullscreens-manager location="icon" />
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import FullscreensManager from '@/components/FullscreensManager'

export default {

  name: 'Fullscreens',

  components: {
    FullscreensManager
  },

  methods: {
    ...mapActions('cms', [
      'fetchFullscreens',
    ])
  },

  created() {
    this.fetchFullscreens()
  }

}
</script>
