<template>
  <div class="styled__container border-red-600 border-2 rounded-none">
    <h2 class="styled__title mb-2">Content Sync</h2>
    <p class="styled__description mb-4">Push the latest content and playlists saved in this cms to the wall and icon.</p>
    <div class="md:flex gap-12 mb-2">
      <sync-runner syncId="wall" class="mt-12 md:mt-0" />
      <sync-runner syncId="icon" class="mt-12 md:mt-0" />
    </div>
  </div>
</template>

<script>
import SyncRunner from './SyncRunner'
export default {
  components: { SyncRunner },
  name: 'SyncManager'
}
</script>