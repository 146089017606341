<template>
  <tr :class="expired && 'opacity-50 bg-gray-200'">
    <td class="text-left">
      {{ schedule.name }}
      <strong v-if="expired" class="block text-xs font-normal text-red-600">(expired)</strong>
    </td>
    <td>
      <span v-if="currentMode">{{ currentMode.name }}</span>
      <span v-else class="text-red-600">No Mode Set</span>
    </td>
    <td>{{ schedule.datetime_start | dateTimePretty }}</td>
    <td>{{ schedule.datetime_end | dateTimePretty }}</td>
    <td>
      <div class="text-right whitespace-nowrap">
        <button class="styled__link" @click="onEdit">Edit</button>
        <button class="styled__link ml-4" @click="onRemove(schedule.id)">Remove</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ConfirmModal from '@/components/ConfirmModal'
import IconScheduleModal from './IconScheduleModal'
import { DateTime } from 'luxon'

export default {
  
  name: 'IconScheduleRow',

  props: {
    schedule: Object
  },

  computed: {
    ...mapState('cms', [
      'iconModes'
    ]),
    currentMode() {
      return this.iconModes
        ? this.iconModes.find(item => item.id === this.schedule.mode)
        : null
    },
    expired() {
      return DateTime.now() > DateTime.fromISO(this.schedule.datetime_end)
    }
  },

  methods: {
    ...mapActions('cms', [
      'deleteScheduledIconConfig',
    ]),

    onEdit() {
      this.showAppModal(IconScheduleModal, {
        schedule: this.schedule
      })
    },

    onRemove(id) {
      this.showAppModal(ConfirmModal, {
        title: 'Delete Scheduled Icon Configuration',
        message: 'Are you sure you want to delete this scheduled icon configuration?',
        onResponse: (confirm, resolve) => confirm
          ? this.deleteScheduledIconConfig({ id }).then(() => resolve())
          : resolve()
      })
    },

  },
  
}
</script>