<template>
  <div
    class="my-12 sm:flex border-l-2 pl-6"
    :class="{
      'border-gray-500 opacity-50 pointer-events-none': disabled,
      'border-red-600': !disabled
    }"
  >
    <div class="mb-4 sm:mb-0 sm:flex-shrink-0 sm:mr-4 sm:w-48">
      <h3 class="text-sm text-gray-600 mb-2">{{ title }}</h3>
      <p class="text-xs text-gray-400 whitespace-pre-wrap">{{ description }}</p>
    </div>
    <div class="w-full">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSection',
  props: {
    title: { type: String, default: 'Section Title' },
    description: { type: String, default: 'Section description.' },
    disabled: { type: Boolean, default: false },
  }
}
</script>